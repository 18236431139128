import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { BlockuiComponent } from '../core/components/blockui/blockui.component';
import { BaseView } from '../core/classes/baseview';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { ButtonsComponent } from '../core/components/buttons/buttons.component';
import { timer } from 'rxjs';
import { ConfigService } from '../core/service/config.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, CommonModule, ButtonsComponent, ProgressBarModule, BlockuiComponent, MessagesModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseView {
  override title = 'RemetricWeb';
  isbusy: boolean = false;

  constructor(private router: Router) {
    super();
    this.isMaintainMode = ConfigService.isMaintenanceMode();
    this.addSubscription(this.router.events.subscribe(data => {
      if (data instanceof RouteConfigLoadStart) {
        this.getPageService().contentLoading();
        this.getPageService().setCompBusy('loading_application');
      } else if (data instanceof RouteConfigLoadEnd) {
        this.getPageService().contentLoaded();
        this.getPageService().setCompNotBusy();
      }
    }))

    this.addSubscription(timer(5000, 500)
      .subscribe(() => {
        this.getPageService().setHasScroll(document.documentElement.scrollHeight > window.innerHeight);
      }));
  }

  setBusy(isbusy: boolean) {
    this.isbusy = isbusy
  }

  isBusy() {
    return this.isbusy;
  }

  hasScroll() {
    return this.getPageService().hasScroll();
  }

  downScroll() {
    const element = document.getElementById('bottom-scroll');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  upScroll() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  pagedownScroll() {
    window.scrollBy({
      top: window.innerHeight - 180,
      behavior: 'smooth'
    });
  }

  pageupScroll() {
    window.scrollBy({
      top: -(window.innerHeight - 180),
      behavior: 'smooth'
    });
  }

  isMaintainMode: boolean = false;

  getMaintainTitle(): string {
    return ConfigService.getAllData("maintenanceTitle");
  }

  getMaintainMsg(): string {
    return ConfigService.getAllData("maintenanceMessage");
  }

  maintaincounter1: number = 0;
  maintaincounter2: number = 0;

  incrementcounter1(): void {
    this.maintaincounter1++;
    this.checkMaintainOff();
  }

  incrementcounter2(): void {
    this.maintaincounter2++;
    this.checkMaintainOff();
  }

  checkMaintainOff() {
    console.log(this.maintaincounter1, this.maintaincounter2);
    if (this.maintaincounter1 == 7 && this.maintaincounter2 == 7) {
      localStorage.setItem("maintenancemodeoverride", ConfigService.getAllData("maintenanceBypass"));
      this.reload();
    }
  }

  reload() {
    location.reload();
  }

}
