import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { PageService } from '../core/service/page.service';
import { MessageService } from 'primeng/api';
import { NavigationService } from '../core/service/navigate.service';
import { CacheService } from '../core/service/cache.service';
import { AuthServiceProxy } from '../../shared/service-proxies/service-proxies';
import { AnonymousGuardService, AuthGuardService } from '../core/guards/auth.guard';
import { RefCodeService } from '../core/service/refcode.service';
import { SweetAlertService } from '../core/service/sweet-alert.service';
import { AppUserService } from './services/appuserservice';
import { ConfigService } from '../core/service/config.service';
import { NotificationService } from './services/notificationservice';
import { EventService } from '../core/service/event.service';

export function initializeApp(appConfig: ConfigService) {
  return () => {
    return appConfig.init().catch((error) => {
      ConfigService.setLoadFailed();
      console.error('Failed to load config. Showing maintenance page.', error);
      return Promise.resolve(); // Continue even if config fails
    });
  };
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
    provideHttpClient(withFetch()),
    provideAnimations(),
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        ConfigService,
        PageService,
        MessageService,
        NavigationService,
        CacheService,
        RefCodeService,
        AppUserService,
        NotificationService,
        EventService
      ],
      multi: true,
    },
    PageService,
    MessageService,
    NavigationService,
    CacheService,
    AuthServiceProxy,
    AuthGuardService,
    AnonymousGuardService,
    RefCodeService,
    SweetAlertService,
    AppUserService,
    NotificationService,
    EventService

  ]
};
